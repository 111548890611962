.vertical-layout {
  display: flex;
  gap: 1rem;
  
  .avatar-container {
    width: 50px;
    height: 50px;
  }
  
  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
  }
}

.active-bees-layout {
  display: flex;
  gap: 1rem;

  .avatar-container {
    width: 90px;
    height: 90px;
  }
  
  .details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
  }
}


