.winner-list {
  &__slider--arrow {
    &-container {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(50%);
    }
  }
  &__page {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: space-between;
    &--container {
      display: flex;
      gap: 20px;
      justify-content: space-between;
    }
  }
  &__paginated--container {
    height: 100%;
    width: calc(50vw - 12px);
    @media (width <= 783px) {
      .winner-list__page--container {
        width: 706px;
      }
      // overflow-x: scroll;
    }
  }
}