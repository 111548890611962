[class^='corner-'], [class*=' corner-'] {
  --top-left: 0 0;
  --top-right: 100% 0;
  --bot-right: 100% 100%;
  --bot-left: 0 100%;
  --corner: 50px;
  clip-path: polygon(var(--top-left), var(--top-right), var(--bot-right), var(--bot-left));
}

.corner {
  $from-end: calc(100% - var(--corner));
  &-TL {
    --top-left: 0 var(--corner), var(--corner) 0;
  }
  &-TR {
    --top-right: #{$from-end} 0, 100% var(--corner);
  }
  &-BR {
    --bot-right: 100% #{$from-end}, #{$from-end} 100%;
  }
  &-BL {
    --bot-left: var(--corner) 100%, 0 #{$from-end};
  }
}

@property --background-color {
  syntax: "<color>"
}