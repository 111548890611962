.row {
    display: flex;
  }
  
  /* Create three equal columns that sits next to each other */
.column {
    flex: 33.33%;
    padding: 5px;
}

.row-image {
    object-fit: cover;
    width: 100%; 
    height: 250px;
}

.NFT-slider {
    .slick-slide > * {
        margin-left: 1em;
    }
}
.NFT-gallery__routing-container {
    display: flex;
    gap: 1em;
}
