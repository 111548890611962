.nominate-form__step {
  display: flex;
  flex-direction: column;
  & .step {
    &_one {
      margin-top: 80px;
      height: 100%;
      display: flex;
      align-items: center;
    }
    &_two {
      height: 100%;
    }
  }
  
}